@charset "utf-8";

/*! contents - mobile */
[class*="web-"] {
  display: none !important;
}
// 브랜드 스토리
.noscarna {

  :lang(ko) {
    //주석
    .section03 .power-cnt .sec03-cnt-wrap .sec03 span .ref-txt {
      top: get-vw-mo(-10px);
    }
  }
  

  .title-wrap {
    .title {
      span {
        em {
          letter-spacing: -1px;
          color: #ff683f;
        }
      }
    }
    .sub-tit {
      @include notoSansM();
    }
    .sub-txt {
      span:last-child {
        margin-top: 10px;
        @include notoSansM();
      }

      em {
        @include notoSansM();
      }
    }
  }
  .sec {
    .mo-title {
      color: #ff683f;
    }
  }
  
  .section01 {
    position: relative;
    background: url(../../images/mo/brand/nc_sec01_bg.png) no-repeat center;
    background-size: cover;

    &:before {
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: get-vw-mo(530px);
      height: get-vw-mo(820px);
      background: url(../../images/mo/brand/nc_sec01_object.png) no-repeat right bottom;
      background-size: contain;
    }
      
    .lang-select{
      width: get-vw-mo(160px);
      margin-left: auto;
      margin-right: 0;
      position: relative;
      top: get-vw-mo(-50px);
      select{
        display: none;
      }
      .select-custom{
        position: relative;
        .btn-select-custom{
          position: relative;
          width: 100%;
          margin: 0;
          padding: get-vw-mo(14px) get-vw-mo(27px);
          font-size: get-vw-mo(28px);
          @include fontE($fontEng, 500);
          border: get-vw-mo(3px) solid #222;
          text-align: left;
          border-radius: get-vw-mo(15px);
          // background:#222;
          // color:#fff;
          cursor: pointer;
          &.on {
            border-radius: get-vw-mo(15px) get-vw-mo(15px) 0 0;

            &::after {
              transform: rotate(180deg);
              top: get-vw-mo(26px);
            }
          }

          &::after{
            content: "";
            position: absolute;
            top: get-vw-mo(32px);
            // top: 50%;
            right: get-vw-mo(18px);
            transform: translateY(-50%);
            width: get-vw-mo(18px);
            height: get-vw-mo(10px);
            background: url(../../images/mo/health/ico_arrow_bottom.png) no-repeat center;
            background-size: contain;
          }
        }
        .select-dropdown{
          position: absolute;
          top: get-vw-mo(68px);
          left: 0;
          display: none;
          width: 100%;
          max-height: get-vw-mo(330px);
          border-radius: 0 0 get-vw-mo(10px) get-vw-mo(10px);
          background: #222;
          overflow-y: auto;
          z-index: 1;
          text-align: left;
        }
        .custom-opt{
          color:#fff;
          padding: get-vw-mo(22px) 0 get-vw-mo(26px) get-vw-mo(30px);
          font-size: get-vw-mo(28px);
          @include fontE($fontEng, 500);
          
          &:hover {
            color:#ff683f;
          }
        }
        &.on{
					.btn-select-custom{
						&::after{
								transform: rotate(180deg);
						}
					}
				}
      }
    }
    .title-wrap {
      padding-bottom: get-vw-mo(680px);
    }
    .sec01-cnt {
      position:absolute;
      bottom: get-vw-mo(-80px);
      left:0;
      // margin-top: get-vw-mo(400px);
      .img-box {
        width: get-vw-mo(670px);
        margin: auto;
        text-align: center;
        position: relative;
        .mo-img {
          position: relative;
          display: block;
          width: 100%;
        }
        .txt-img {
          position: absolute;
          top: get-vw-mo(-115px);
          bottom: get-vw-mo(268px);left: 50%;
          width: get-vw-mo(175px);
          height: get-vw-mo(175px);
          margin-left: get-vw-mo(-150px);
        }
      }
    }
  }
  .section02 {
    .feature-cnt {
      position: relative;
      padding-bottom: get-vw-mo(245px);
      margin-top: get-vw-mo(66px);
      max-width: 670px;
      margin: 0 auto;
      .sec02-circle-wrap {
        display: block;
        text-align: center;
        
        .sec02-circle {
          position: absolute;
          width: get-vw-mo(210px);
          height: get-vw-mo(210px);
          padding: get-vw-mo(10px);
          align-self: center;
          border: 3px dotted #ff8f70;
          display: flex;
          justify-content: center;
          border-radius: 100%;
          box-sizing: border-box;
          .txt {
            align-self: center;
            word-break: keep-all;
            @include text-overflow-multi(5);
            @include notoSansM();
            font-size: get-vw-mo(22px);
            line-height: 1.4;
          }

          &:nth-child(1){
            left:0;
            bottom:get-vw-mo(70px);
          }
          &:nth-child(3){
            right:0;
            bottom:get-vw-mo(70px);
          }
          &:nth-child(4){
            left:get-vw-mo(230px);
            bottom:0;
          }
          
        }


        .img-circle {
          position: relative;
          width: get-vw-mo(441px);
          margin: 0 auto;
          .mo-img {
            width: 100%;
          }
          .product-img {
            position: absolute;
            top: get-vw-mo(83px);left: get-vw-mo(-40px);
            width: get-vw-mo(570px);
          }
        }
    }
    }
  }
  .section03 {
    background: url(../../images/mo/brand/nc_sec03_bg.png) no-repeat center;
    background-size: cover;
    .title-wrap {
      margin-bottom: get-vw-mo(80px);
      .title {
        span {
          color: #fff;
          em {
            color: #ff683f;
          }
        }
      }
    }
    .power-cnt {
      .sec03-cnt-wrap {
        text-align: center;
        .sec03 {
          position: relative;
          width: get-vw-mo(446px);
          margin: 0 auto get-vw-mo(90px);
          padding-bottom: get-vw-mo(40px);
          text-align: center;
          box-sizing: border-box;
          overflow: hidden;
          p {
            margin-bottom: get-vw-mo(30px);
            text-align: center;
            font-size: get-vw-mo(28px);
            line-height: 1.7;
            color: #fff;
            span {
              display: block;
              font-size: get-vw-mo(22px);
            }
          }
          .move-box {
            text-align: center;
            .ui-move-num {
              display: inline-block;
              @include notoSansB();
              font-size: get-vw-mo(62px);
              color: #fff;
              & + .ui-move-num {
                margin-right: -4px;
              }
            }
          }
          &:after {
            content:"";
            display: block;
            clear: both;
            position: absolute;
            bottom: 0;left: 0;right: 0;
            width: 0;height: get-vw-mo(10px);
            background-color: #ff683f;
            transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;
          }
          &.on {
            &:after {
              width: 100%;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .sec03-references {
        padding: 0 get-vw(20px);
        text-align: left;
        font-size: get-vw-mo(16px);
        color: #fff;
        line-height: 1.5;
      }
    }
    .inner-references {
      margin-top: get-vw-mo(100px);
      text-align: left;
      color:#fff;

      p {
        text-align: left;
        font-size: get-vw-mo(24px);
        line-height: 1.5;
      }
      span {
        display: block;
        text-align: left;
        font-size: get-vw-mo(24px);
        line-height: 1.2;
        a {
          font-size: get-vw-mo(24px);
          line-height: 1.5;
        }
      }
    }
  }
  .section05 {
    .media-cnt {
      .swiper-pagination {
        .swiper-pagination-bullet-active {
          background-color: #ff683f;
        }
      }
    }
  }
}

//제품정보
.product-info {
  .title-wrap {
    .title {
      span {
        em {
          color: #ff805e;
        }
      }
    }
  }
  .section01 {
    .product-swiper {
      .swiper-wrapper {
        .swiper-slide.on {
          .active-box {
            opacity: 1;
            background-color: #ff805e;
          }
        }
      }
    }
  }
  .section02 {
    .product-detail-wrap {
      .title {
        a {
          background-color: #ff805e;
        }
      }
    }
  }
  .section03 {
    padding-bottom: get-vw-mo(100px);
    .swiper-pagination {
      .swiper-pagination-bullet-active {
        background-color: #ff805e;
      }
    }
  }
}

// 궁금해요
.noscarna-qna {
  .title-wrap {
    .title {
      span {
        em {
          color: #ff683f;
        }
      }
    }
  }
  .section01 {
    background-color: #fff5f2;
    .qna-sec01-cnt {
      margin: get-vw-mo(65px) 0 get-vw-mo(50px);
      .sec01-cnt {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        .img-box {
          width: 35%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt-box {
          width: 65%;
          padding: get-vw-mo(50px) get-vw-mo(30px);
          background-color: #fc754f;
          box-sizing: border-box;
          .step {
            display: block;
            margin-bottom: 10px;
            font-size: get-vw-mo(28px);
            color: #fff;
            @include fontE($fontEng, 500);
          }
          .tit {
            margin-bottom: 20px;
            @include notoSansM();
            font-size: get-vw-mo(38px);
            color: #fff;
          }
          .txt {
            font-size: get-vw-mo(28px);
            line-height: 1.5;
            color: #fff;
          }
        }
        & + .sec01-cnt{
          margin-top:get-vw-mo(40px);
        }
        &:nth-child(even) {
          .txt-box {
            background-color: #fff;
            .step {
              color: #fc754f;
            }
            .tit {
              color: #222;
            }
            .txt {
              color: #222;
            }
          }
          
        }
        .shadow-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
    .qna-sec01-info {
      .txt {
        display: block;
        position: relative;
        padding-left: get-vw-mo(45px);
        font-size: get-vw-mo(26px);
        line-height: 1.6;
        color: #797e76;
        &:before {
          content:"";
          display: block;
          clear: both;
          position: absolute;
          top: 5px;left: 0;
          width: get-vw-mo(32px);height: get-vw-mo(32px);
          background: url(../../images/mo/ico_detail.png);
          background-size: cover;
          opacity: .6;
        }
      }
    }
  }
  .section02 {
    padding-bottom: get-vw-mo(145px);
    .title-wrap {
      .title {
        span {
          em {
            margin-right: get-vw-mo(-13px);
          }
        }
      }
    }
    .qna-sec02-cnt {
      position: relative;
      width: 100%;
      height: get-vw-mo(654px);
      margin: get-vw-mo(36px) auto get-vw-mo(80px);
      box-sizing: border-box;
      background: url(../../images/mo/brand/nc03_sec02_img01.png) no-repeat center;
      background-size: contain;
      .ingredient-wrap {
        .txt {
          position: absolute;
          display: block;
          font-size: get-vw-mo(28px);
          line-height: 1.6;
          color: #fff;
          &:nth-child(1) {
            top: get-vw-mo(246px);
            left: get-vw-mo(79px);
          }
          &:nth-child(2) {
            top: get-vw-mo(246px);
            right: get-vw-mo(113px);
          }
          &:nth-child(3) {
            left: get-vw-mo(275px);
            bottom: get-vw-mo(49px);
          }
        }
      }
    }
    .btm-txt {
      .txt-box {
        img {
          display: block;
          max-width: get-vw-mo(234px);
          margin-bottom: get-vw-mo(30px);
        }
        .txt {
          display: block;
          letter-spacing: -1px;
          word-break: keep-all;
          font-size: get-vw-mo(25px);
          line-height: 1.5;
          em {
            position: relative;
            margin-right: -4px;
            &:after {
              content:"";
              display: block;
              clear: both;
              position: absolute;
              bottom: 2px;
              width: 100%;height: get-vw-mo(14px);
              background-color: #ffe4dc;
              z-index: -1;
            }
          }
        }
      }
      .img-box {
        width: 100%;
        margin-top: get-vw-mo(40px);
        text-align: center;
        img {
          max-width: 100%;
        }
      }
    }
  }
  .section03 {
    position: relative;
    .bg-sec {
      position: absolute;
      top: 0;left: 0;
      width: 100%;height: 100%;
      background: url(../../images/mo/brand/nc03_sec03_bg.png) no-repeat center;
      background-size: cover;
      z-index: -1;
    }
    .sec03-circle-wrap {
      position: relative;
      display: flex;
      width: get-vw-mo(670px);
      margin: get-vw-mo(70px) auto 0;
      padding-bottom: get-vw-mo(269px);
      justify-content: space-between;
      .sec03-circle {
        display: flex;
        width: get-vw-mo(287px);
        height: get-vw-mo(287px);
        padding: get-vw-mo(35px);
        border-radius: 100%;
        background-color: rgba(250,104,64,0.7);
        justify-content: center;
        box-sizing: border-box;
        .txt {
          align-self: center;
          @include text-overflow-multi(5);
          font-size: get-vw-mo(32px);
          line-height: 1.4;
          color: #fff;
          text-align:center;
        }
        &:nth-child(2) {
          position: absolute;
          top: get-vw-mo(268px);
          left: 50%;
          margin-left: get-vw-mo(-143px);
        }
      }
    }
  }
  .section04 {
    background-color: #f1f5f8;
    .title-wrap {
      .title {
        margin-bottom: get-vw-mo(67px);
      }
    }
    .ui-accordion {
      .accordion-item {
        .btn {
          .ico-q {
            color: #ff683f;
          }
        }
      }
    }  
  }
}

// 태블릿
@media screen and (min-width: 751px) and (max-width: 1023px) {
  // 브랜드 스토리
  .noscarna {

    :lang(ko) {
      //주석
      .section03 .power-cnt .sec03-cnt-wrap .sec03 span .ref-txt {
        top: -10px;
      }
    }
    

    .section01 {
      .lang-select{
        top: -50px;
        width: 160px;
        .select-custom{
          .btn-select-custom{
            padding: 14px 27px;
            font-size: 28px;
            border: 3px solid #222;
            border-radius: 15px;
            &.on {
              border-radius: 15px 15px 0 0;

              &::after {
                transform: rotate(180deg);
                top: 26px;
              }
            }

            &::after{
              top: 32px;
              right: 18px;
              width: 18px;
              height: 10px;
            }
          }
          .select-dropdown{
            top: 68px;
            max-height: 330px;
            border-radius: 0 0 10px 10px;
          }
          .custom-opt{
            padding: 22px 0 26px 30px;
            font-size: 28px;
          }
        }
      }
      .title-wrap {
        padding-bottom: 680px;
      }
      .sec01-cnt {
        // margin-top: 400px;
        bottom: -80px;
        .img-box {
          width: 670px;
          .txt-img {
            // bottom: 334px;
            top: -115px;
            width: 175px;
            height: 175px;
            margin-left: -150px;
          }
        }
        
      }
    }
    .section02 {
      .feature-cnt {
        margin-top: 66px;
        padding-bottom: 245px;
        .sec02-circle-wrap {
          .sec02-circle {
            width: 210px;
            height: 210px;
            padding: 10px;
            .txt {
              font-size: 22px;
            }
            &:nth-child(1){
              bottom:70px;
            }
            &:nth-child(3){
              bottom:70px;
            }
            &:nth-child(4){
              left:230px;
            }
          }
          .img-circle {
            width: 441px;
            .product-img {
              top: 83px;left: -40px;
              width: 570px;
            }
          }
          // &:last-child {
          //   left: -33px;
          // }
        }
      }
    }
    .section03 {
      .title-wrap {
        margin-bottom:80px;
      }
      .power-cnt {
        .sec03-cnt-wrap {
          .sec03 {
            width: 446px;
            margin: 0 auto 90px;
            padding-bottom: 40px;
            p {
              margin-bottom: 30px;
              font-size: 28px;
              span {
                font-size: 22px;
              }
            }
            .move-box {
              .ui-move-num {
                font-size: 62px;
              }
            }
            &:after {
              height: 10px;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .inner-references {
        margin-top: 100px;
  
        .reference{
          padding: 30px 0;
          p {
            font-size: 24px;
          }
          span {
            font-size: 24px;
            a {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
  
  // 제품정보
  .product-info .section03 {
    padding-bottom: 100px;
  }

  // 궁금해요
  .noscarna-qna {
    .title-wrap {
      padding-bottom: 160px 0 60px;
    }
    .section01 {
      .qna-sec01-cnt {
        margin: 65px 0 50px;
        .sec01-cnt {
          max-height: 328px;
          .txt-box {
            padding: 50px 30px;
            .step {
              font-size: 28px;
            }
            .tit {
              font-size: 38px;
            }
            .txt {
              font-size: 28px;
            }
          }
          & + .sec01-cnt{
            margin-top:40px;
          }
        }
      }
      .qna-sec01-info {
        .txt {
          padding-left: 45px;
          font-size: 26px;
          &:before {
            width: 32px;height: 32px;
          }
        }
      }
    }
    .section02 {
      padding-bottom: 145px;
      .title-wrap {
        .title {
          span {
            em {
              margin-right: -13px;
            }
          }
        }
      }
      .qna-sec02-cnt {
        max-width: 670px;
        height: 654px;
        margin: 36px auto 80px;
        background-size: cover;
        .ingredient-wrap {
          .txt {
            font-size: 28px;
            &:nth-child(1) {
              top: 246px;
              left: 79px;
            }
            &:nth-child(2) {
              top: 246px;
              right: 113px;
            }
            &:nth-child(3) {
              left: 275px;
              bottom: 49px;
            }
          }
        }
      }
      .btm-txt {
        .txt-box {
          img {
            max-width: 234px;
            margin-bottom: 30px;
          }
          .txt {
            font-size: 32px;
            em {
              &:after {
                height: 14px;
              }
            }
          }
        }
        .img-box {
          margin-top: 40px;
        }
      }
    }
    .section03 {
      .sec03-circle-wrap {
        width: 670px;
        margin: 70px auto 0;
        padding-bottom: 269px;
        .sec03-circle {
          width: 287px;
          height: 287px;
          padding: 35px;
          .txt {
            font-size: 32px;
          }
          &:nth-child(2) {
            top: 268px;
            margin-left: -143px;
          }
        }
      }
    }
    .section04 {
      padding-bottom: 160px;
      .title-wrap {
        .title {
          margin-bottom: 67px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  
  .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt {
    max-height: inherit !important;
  }
  .noscarna-qna .section02 .qna-sec02-cnt {
    background-size: contain;
  }

}



/* 다국어 */

/* 영문 */
:lang(en) {
    #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
      font-size:get-vw-mo(23px);
    }

    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
      font-size: get-vw-mo(26px);
    }

    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
      top: get-vw-mo(235px);
      left: get-vw-mo(68px);
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
      top: get-vw-mo(235px);
      right: get-vw-mo(107px);
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
      left: get-vw-mo(250px);
    }

    .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
      font-size: get-vw-mo(19px);
    }

    .noscarna-qna .section02 .btm-txt .txt-box .txt {
      font-size: get-vw-mo(31px);
    }

    .product-info .section02 .precautions .btn-download {
      width: get-vw-mo(390px);
    }

    .product-info .section02 .product-detail-wrap .title a {
      padding: get-vw-mo(22px) get-vw-mo(30px);
    }

    .noscarna .sec03-cnt-wrap p .ref-txt {
      top: get-vw-mo(-8px);
    }
    .noscarna .sec03-cnt-wrap span .ref-txt {
      top: get-vw-mo(-8px);
      left: 0;
    }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
      line-height: 1.55;
    }


    @media screen and (max-width: 1023px) and (min-width: 750px) {
      #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
        font-size:23px;
      }

      .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
        padding: 29px 30px;
      }

      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
        font-size: 26px;
      }
  
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
        top: 235px;
        left: 68px;
      }
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
        top: 235px;
        right: 107px;
      }
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
        left: 250px;
      }

      .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
        font-size: 19px;
      }

      .noscarna-qna .section02 .btm-txt .txt-box .txt {
        font-size: 31px;
      }

      .product-info .section02 .precautions .btn-download {
        width: 390px;
      }

      .noscarna .sec03-cnt-wrap p .ref-txt {
        top: -8px;
      }
      .noscarna .sec03-cnt-wrap span .ref-txt {
        top: -8px;
        left: 0;
      }
      
    }
  
}

/* 일문 */
:lang(ja) {

  .ui-accordion .accordion-item .btn .txt {
    @include notoSansM();
  }

  .ui-accordion .accordion-item .detail p {
    @include notoSansDL();
  }

  .brand .title-wrap .sub-txt span {
    word-break: break-all;
  }

  .brand .title-wrap .sub-txt {
    max-width: 810px;
  }

  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1){
    top: get-vw-mo(244px);
    left: get-vw-mo(53px);
  }
  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2){
    top: get-vw-mo(244px);
    right: get-vw-mo(90px);
  }
  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: get-vw-mo(233px);
    bottom: get-vw-mo(53px);
  }

  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: get-vw-mo(23px);
  }

  .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle .txt {
    font-size: get-vw-mo(28px);
  }

  .noscarna .sec03-cnt-wrap p .ref-txt {
    top: get-vw-mo(-14px);
  }
  .noscarna .sec03-cnt-wrap span .ref-txt {
    top: get-vw-mo(-10px);
    left: 0;
  }

  

  @media screen and (max-width: 1023px) and (min-width: 750px) {
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit {
      font-size: 36px;
    }
  
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
      padding: 31px 30px;
    }
  
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
      font-size: 23px;
    }
  
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1){
      top: 244px;
      left: 53px;
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2){
      top: 244px;
      right: 90px;
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
      left: 233px;
      bottom: 53px;
    }

    .noscarna .sec03-cnt-wrap p .ref-txt {
      top: -14px;
    }
    .noscarna .sec03-cnt-wrap span .ref-txt {
      top: -10px;
      left: 0;
    }
  }

}

/* 중문 */
:lang(zh) {
  .ui-accordion .accordion-item .btn .txt {
    @include notoSansM();
  }

  .ui-accordion .accordion-item .detail p {
    @include notoSansDL();
  }

  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    left: get-vw-mo(120px);
  }
  .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: get-vw-mo(280px);
    bottom: get-vw-mo(37px);
  }

  .noscarna .sec03-cnt-wrap p .ref-txt {
    top: get-vw-mo(-15px);
  }
  .noscarna .sec03-cnt-wrap span .ref-txt {
    top: get-vw-mo(-9px);
    left: 0;
  }

  @media screen and (max-width: 1023px) and (min-width: 750px) {
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
      left: 120px;
    }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
      left: 280px;
      bottom: 37px;
    }

    .noscarna .sec03-cnt-wrap p .ref-txt {
      top: -15px;
    }
    .noscarna .sec03-cnt-wrap span .ref-txt {
      top: -9px;
      left: 0;
    }
  }
}
