@charset "UTF-8";
/**
	* Computes a CSS calc function that betweens a value from
	* A to B over viewport-width A to viewport-width B.
	* Requires a media query to cap the value at B.
	* vw 단위를 활용하여 미디어쿼리 분기의 중간 상태값을
	* 계산해주는 SASS 함수.
	* 간격이나 폰트사이즈에 적용하면 자연스러운
	* 반응형 사이즈 처리가 가능 함.
	* 참고사이트 http://dohoons.com/blog/1364
	*/
/**
	* Read the article: http://sassbreak.com/viewport-relative-headings-with-sass
	* px 를 vw 로 자동으로 바꿔 줌.
	* max-breakpoint 를 설정하면 미디어쿼리 브레이크 포인트가 됨.
	* 그래서 미디어쿼리 브레이크 포인트에 맞춰 px 가 vw 로 변환됨.
	* 100% 보다 더 크게 적용 하고 싶으면 return 부분을 수정하면됨.
	* 참고사이트 http://sassbreak.com/viewport-relative-headings-with-sass/
	*/
@media (max-width: 1023px) {
  /*! contents - mobile */
  [class*="web-"] {
    display: none !important; }
  .noscarna :lang(ko) .section03 .power-cnt .sec03-cnt-wrap .sec03 span .ref-txt {
    top: -1.33333vw; }
  .noscarna .title-wrap .title span em {
    letter-spacing: -1px;
    color: #ff683f; }
  .noscarna .title-wrap .sub-tit:lang(ko) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .noscarna .title-wrap .sub-tit:lang(en) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .noscarna .title-wrap .sub-tit:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  .noscarna .title-wrap .sub-tit:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  .noscarna .title-wrap .sub-txt span:last-child {
    margin-top: 10px; }
    .noscarna .title-wrap .sub-txt span:last-child:lang(ko) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .noscarna .title-wrap .sub-txt span:last-child:lang(en) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .noscarna .title-wrap .sub-txt span:last-child:lang(ja) {
      font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
    .noscarna .title-wrap .sub-txt span:last-child:lang(zh) {
      font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
  .noscarna .title-wrap .sub-txt em:lang(ko) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .noscarna .title-wrap .sub-txt em:lang(en) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .noscarna .title-wrap .sub-txt em:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  .noscarna .title-wrap .sub-txt em:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  .noscarna .sec .mo-title {
    color: #ff683f; }
  .noscarna .section01 {
    position: relative;
    background: url(../../images/mo/brand/nc_sec01_bg.png) no-repeat center;
    background-size: cover; }
    .noscarna .section01:before {
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 70.66667vw;
      height: 109.33333vw;
      background: url(../../images/mo/brand/nc_sec01_object.png) no-repeat right bottom;
      background-size: contain; }
    .noscarna .section01 .lang-select {
      width: 21.33333vw;
      margin-left: auto;
      margin-right: 0;
      position: relative;
      top: -6.66667vw; }
      .noscarna .section01 .lang-select select {
        display: none; }
      .noscarna .section01 .lang-select .select-custom {
        position: relative; }
        .noscarna .section01 .lang-select .select-custom .btn-select-custom {
          position: relative;
          width: 100%;
          margin: 0;
          padding: 1.86667vw 3.6vw;
          font-size: 3.73333vw;
          font-family: "Barlow";
          font-weight: 500;
          border: 0.4vw solid #222;
          text-align: left;
          border-radius: 2vw;
          cursor: pointer; }
          .noscarna .section01 .lang-select .select-custom .btn-select-custom.on {
            border-radius: 2vw 2vw 0 0; }
            .noscarna .section01 .lang-select .select-custom .btn-select-custom.on::after {
              transform: rotate(180deg);
              top: 3.46667vw; }
          .noscarna .section01 .lang-select .select-custom .btn-select-custom::after {
            content: "";
            position: absolute;
            top: 4.26667vw;
            right: 2.4vw;
            transform: translateY(-50%);
            width: 2.4vw;
            height: 1.33333vw;
            background: url(../../images/mo/health/ico_arrow_bottom.png) no-repeat center;
            background-size: contain; }
        .noscarna .section01 .lang-select .select-custom .select-dropdown {
          position: absolute;
          top: 9.06667vw;
          left: 0;
          display: none;
          width: 100%;
          max-height: 44vw;
          border-radius: 0 0 1.33333vw 1.33333vw;
          background: #222;
          overflow-y: auto;
          z-index: 1;
          text-align: left; }
        .noscarna .section01 .lang-select .select-custom .custom-opt {
          color: #fff;
          padding: 2.93333vw 0 3.46667vw 4vw;
          font-size: 3.73333vw;
          font-family: "Barlow";
          font-weight: 500; }
          .noscarna .section01 .lang-select .select-custom .custom-opt:hover {
            color: #ff683f; }
        .noscarna .section01 .lang-select .select-custom.on .btn-select-custom::after {
          transform: rotate(180deg); }
    .noscarna .section01 .title-wrap {
      padding-bottom: 90.66667vw; }
    .noscarna .section01 .sec01-cnt {
      position: absolute;
      bottom: -10.66667vw;
      left: 0; }
      .noscarna .section01 .sec01-cnt .img-box {
        width: 89.33333vw;
        margin: auto;
        text-align: center;
        position: relative; }
        .noscarna .section01 .sec01-cnt .img-box .mo-img {
          position: relative;
          display: block;
          width: 100%; }
        .noscarna .section01 .sec01-cnt .img-box .txt-img {
          position: absolute;
          top: -15.33333vw;
          bottom: 35.73333vw;
          left: 50%;
          width: 23.33333vw;
          height: 23.33333vw;
          margin-left: -20vw; }
  .noscarna .section02 .feature-cnt {
    position: relative;
    padding-bottom: 32.66667vw;
    margin-top: 8.8vw;
    max-width: 670px;
    margin: 0 auto; }
    .noscarna .section02 .feature-cnt .sec02-circle-wrap {
      display: block;
      text-align: center; }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle {
        position: absolute;
        width: 28vw;
        height: 28vw;
        padding: 1.33333vw;
        align-self: center;
        border: 3px dotted #ff8f70;
        display: flex;
        justify-content: center;
        border-radius: 100%;
        box-sizing: border-box; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
          align-self: center;
          word-break: keep-all;
          text-overflow: ellipsis;
          white-space: normal;
          word-wrap: break-word;
          overflow: hidden;
          -webkit-line-clamp: 5;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-size: 2.93333vw;
          line-height: 1.4; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt:lang(ko) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt:lang(en) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(1) {
          left: 0;
          bottom: 9.33333vw; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(3) {
          right: 0;
          bottom: 9.33333vw; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(4) {
          left: 30.66667vw;
          bottom: 0; }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .img-circle {
        position: relative;
        width: 58.8vw;
        margin: 0 auto; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .img-circle .mo-img {
          width: 100%; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .img-circle .product-img {
          position: absolute;
          top: 11.06667vw;
          left: -5.33333vw;
          width: 76vw; }
  .noscarna .section03 {
    background: url(../../images/mo/brand/nc_sec03_bg.png) no-repeat center;
    background-size: cover; }
    .noscarna .section03 .title-wrap {
      margin-bottom: 10.66667vw; }
      .noscarna .section03 .title-wrap .title span {
        color: #fff; }
        .noscarna .section03 .title-wrap .title span em {
          color: #ff683f; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap {
      text-align: center; }
      .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 {
        position: relative;
        width: 59.46667vw;
        margin: 0 auto 12vw;
        padding-bottom: 5.33333vw;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden; }
        .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
          margin-bottom: 4vw;
          text-align: center;
          font-size: 3.73333vw;
          line-height: 1.7;
          color: #fff; }
          .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p span {
            display: block;
            font-size: 2.93333vw; }
        .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box {
          text-align: center; }
          .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num {
            display: inline-block;
            font-size: 8.26667vw;
            color: #fff; }
            .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(ko) {
              font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(en) {
              font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(ja) {
              font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 900; }
            .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(zh) {
              font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 900; }
            .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num + .ui-move-num {
              margin-right: -4px; }
        .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03:after {
          content: "";
          display: block;
          clear: both;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 0;
          height: 1.33333vw;
          background-color: #ff683f;
          transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all; }
        .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03.on:after {
          width: 100%; }
        .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03:last-child {
          margin-bottom: 0; }
    .noscarna .section03 .power-cnt .sec03-references {
      padding: 0 1.04167vw;
      text-align: left;
      font-size: 2.13333vw;
      color: #fff;
      line-height: 1.5; }
    .noscarna .section03 .inner-references {
      margin-top: 13.33333vw;
      text-align: left;
      color: #fff; }
      .noscarna .section03 .inner-references p {
        text-align: left;
        font-size: 3.2vw;
        line-height: 1.5; }
      .noscarna .section03 .inner-references span {
        display: block;
        text-align: left;
        font-size: 3.2vw;
        line-height: 1.2; }
        .noscarna .section03 .inner-references span a {
          font-size: 3.2vw;
          line-height: 1.5; }
  .noscarna .section05 .media-cnt .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ff683f; }
  .product-info .title-wrap .title span em {
    color: #ff805e; }
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .active-box {
    opacity: 1;
    background-color: #ff805e; }
  .product-info .section02 .product-detail-wrap .title a {
    background-color: #ff805e; }
  .product-info .section03 {
    padding-bottom: 13.33333vw; }
    .product-info .section03 .swiper-pagination .swiper-pagination-bullet-active {
      background-color: #ff805e; }
  .noscarna-qna .title-wrap .title span em {
    color: #ff683f; }
  .noscarna-qna .section01 {
    background-color: #fff5f2; }
    .noscarna-qna .section01 .qna-sec01-cnt {
      margin: 8.66667vw 0 6.66667vw; }
      .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt {
        position: relative;
        display: flex;
        width: 100%;
        height: auto; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .img-box {
          width: 35%; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .img-box img {
            width: 100%;
            height: 100%; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
          width: 65%;
          padding: 6.66667vw 4vw;
          background-color: #fc754f;
          box-sizing: border-box; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .step {
            display: block;
            margin-bottom: 10px;
            font-size: 3.73333vw;
            color: #fff;
            font-family: "Barlow";
            font-weight: 500; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit {
            margin-bottom: 20px;
            font-size: 5.06667vw;
            color: #fff; }
            .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(ko) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(en) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(ja) {
              font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
            .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(zh) {
              font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .txt {
            font-size: 3.73333vw;
            line-height: 1.5;
            color: #fff; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt + .sec01-cnt {
          margin-top: 5.33333vw; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(even) .txt-box {
          background-color: #fff; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(even) .txt-box .step {
            color: #fc754f; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(even) .txt-box .tit {
            color: #222; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(even) .txt-box .txt {
            color: #222; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .shadow-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2); }
    .noscarna-qna .section01 .qna-sec01-info .txt {
      display: block;
      position: relative;
      padding-left: 6vw;
      font-size: 3.46667vw;
      line-height: 1.6;
      color: #797e76; }
      .noscarna-qna .section01 .qna-sec01-info .txt:before {
        content: "";
        display: block;
        clear: both;
        position: absolute;
        top: 5px;
        left: 0;
        width: 4.26667vw;
        height: 4.26667vw;
        background: url(../../images/mo/ico_detail.png);
        background-size: cover;
        opacity: .6; }
  .noscarna-qna .section02 {
    padding-bottom: 19.33333vw; }
    .noscarna-qna .section02 .title-wrap .title span em {
      margin-right: -1.73333vw; }
    .noscarna-qna .section02 .qna-sec02-cnt {
      position: relative;
      width: 100%;
      height: 87.2vw;
      margin: 4.8vw auto 10.66667vw;
      box-sizing: border-box;
      background: url(../../images/mo/brand/nc03_sec02_img01.png) no-repeat center;
      background-size: contain; }
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
        position: absolute;
        display: block;
        font-size: 3.73333vw;
        line-height: 1.6;
        color: #fff; }
        .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
          top: 32.8vw;
          left: 10.53333vw; }
        .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
          top: 32.8vw;
          right: 15.06667vw; }
        .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
          left: 36.66667vw;
          bottom: 6.53333vw; }
    .noscarna-qna .section02 .btm-txt .txt-box img {
      display: block;
      max-width: 31.2vw;
      margin-bottom: 4vw; }
    .noscarna-qna .section02 .btm-txt .txt-box .txt {
      display: block;
      letter-spacing: -1px;
      word-break: keep-all;
      font-size: 3.33333vw;
      line-height: 1.5; }
      .noscarna-qna .section02 .btm-txt .txt-box .txt em {
        position: relative;
        margin-right: -4px; }
        .noscarna-qna .section02 .btm-txt .txt-box .txt em:after {
          content: "";
          display: block;
          clear: both;
          position: absolute;
          bottom: 2px;
          width: 100%;
          height: 1.86667vw;
          background-color: #ffe4dc;
          z-index: -1; }
    .noscarna-qna .section02 .btm-txt .img-box {
      width: 100%;
      margin-top: 5.33333vw;
      text-align: center; }
      .noscarna-qna .section02 .btm-txt .img-box img {
        max-width: 100%; }
  .noscarna-qna .section03 {
    position: relative; }
    .noscarna-qna .section03 .bg-sec {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/mo/brand/nc03_sec03_bg.png) no-repeat center;
      background-size: cover;
      z-index: -1; }
    .noscarna-qna .section03 .sec03-circle-wrap {
      position: relative;
      display: flex;
      width: 89.33333vw;
      margin: 9.33333vw auto 0;
      padding-bottom: 35.86667vw;
      justify-content: space-between; }
      .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle {
        display: flex;
        width: 38.26667vw;
        height: 38.26667vw;
        padding: 4.66667vw;
        border-radius: 100%;
        background-color: rgba(250, 104, 64, 0.7);
        justify-content: center;
        box-sizing: border-box; }
        .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle .txt {
          align-self: center;
          text-overflow: ellipsis;
          white-space: normal;
          word-wrap: break-word;
          overflow: hidden;
          -webkit-line-clamp: 5;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-size: 4.26667vw;
          line-height: 1.4;
          color: #fff;
          text-align: center; }
        .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle:nth-child(2) {
          position: absolute;
          top: 35.73333vw;
          left: 50%;
          margin-left: -19.06667vw; }
  .noscarna-qna .section04 {
    background-color: #f1f5f8; }
    .noscarna-qna .section04 .title-wrap .title {
      margin-bottom: 8.93333vw; }
    .noscarna-qna .section04 .ui-accordion .accordion-item .btn .ico-q {
      color: #ff683f; } }

@media screen and (max-width: 1023px) and (min-width: 751px) and (max-width: 1023px) {
  .noscarna :lang(ko) .section03 .power-cnt .sec03-cnt-wrap .sec03 span .ref-txt {
    top: -10px; }
  .noscarna .section01 .lang-select {
    top: -50px;
    width: 160px; }
    .noscarna .section01 .lang-select .select-custom .btn-select-custom {
      padding: 14px 27px;
      font-size: 28px;
      border: 3px solid #222;
      border-radius: 15px; }
      .noscarna .section01 .lang-select .select-custom .btn-select-custom.on {
        border-radius: 15px 15px 0 0; }
        .noscarna .section01 .lang-select .select-custom .btn-select-custom.on::after {
          transform: rotate(180deg);
          top: 26px; }
      .noscarna .section01 .lang-select .select-custom .btn-select-custom::after {
        top: 32px;
        right: 18px;
        width: 18px;
        height: 10px; }
    .noscarna .section01 .lang-select .select-custom .select-dropdown {
      top: 68px;
      max-height: 330px;
      border-radius: 0 0 10px 10px; }
    .noscarna .section01 .lang-select .select-custom .custom-opt {
      padding: 22px 0 26px 30px;
      font-size: 28px; }
  .noscarna .section01 .title-wrap {
    padding-bottom: 680px; }
  .noscarna .section01 .sec01-cnt {
    bottom: -80px; }
    .noscarna .section01 .sec01-cnt .img-box {
      width: 670px; }
      .noscarna .section01 .sec01-cnt .img-box .txt-img {
        top: -115px;
        width: 175px;
        height: 175px;
        margin-left: -150px; }
  .noscarna .section02 .feature-cnt {
    margin-top: 66px;
    padding-bottom: 245px; }
    .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle {
      width: 210px;
      height: 210px;
      padding: 10px; }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
        font-size: 22px; }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(1) {
        bottom: 70px; }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(3) {
        bottom: 70px; }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:nth-child(4) {
        left: 230px; }
    .noscarna .section02 .feature-cnt .sec02-circle-wrap .img-circle {
      width: 441px; }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .img-circle .product-img {
        top: 83px;
        left: -40px;
        width: 570px; }
  .noscarna .section03 .title-wrap {
    margin-bottom: 80px; }
  .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 {
    width: 446px;
    margin: 0 auto 90px;
    padding-bottom: 40px; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
      margin-bottom: 30px;
      font-size: 28px; }
      .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p span {
        font-size: 22px; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num {
      font-size: 62px; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03:after {
      height: 10px; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03:last-child {
      margin-bottom: 0; }
  .noscarna .section03 .inner-references {
    margin-top: 100px; }
    .noscarna .section03 .inner-references .reference {
      padding: 30px 0; }
      .noscarna .section03 .inner-references .reference p {
        font-size: 24px; }
      .noscarna .section03 .inner-references .reference span {
        font-size: 24px; }
        .noscarna .section03 .inner-references .reference span a {
          font-size: 24px; }
  .product-info .section03 {
    padding-bottom: 100px; }
  .noscarna-qna .title-wrap {
    padding-bottom: 160px 0 60px; }
  .noscarna-qna .section01 .qna-sec01-cnt {
    margin: 65px 0 50px; }
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt {
      max-height: 328px; }
      .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
        padding: 50px 30px; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .step {
          font-size: 28px; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit {
          font-size: 38px; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .txt {
          font-size: 28px; }
      .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt + .sec01-cnt {
        margin-top: 40px; }
  .noscarna-qna .section01 .qna-sec01-info .txt {
    padding-left: 45px;
    font-size: 26px; }
    .noscarna-qna .section01 .qna-sec01-info .txt:before {
      width: 32px;
      height: 32px; }
  .noscarna-qna .section02 {
    padding-bottom: 145px; }
    .noscarna-qna .section02 .title-wrap .title span em {
      margin-right: -13px; }
    .noscarna-qna .section02 .qna-sec02-cnt {
      max-width: 670px;
      height: 654px;
      margin: 36px auto 80px;
      background-size: cover; }
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
        font-size: 28px; }
        .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
          top: 246px;
          left: 79px; }
        .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
          top: 246px;
          right: 113px; }
        .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
          left: 275px;
          bottom: 49px; }
    .noscarna-qna .section02 .btm-txt .txt-box img {
      max-width: 234px;
      margin-bottom: 30px; }
    .noscarna-qna .section02 .btm-txt .txt-box .txt {
      font-size: 32px; }
      .noscarna-qna .section02 .btm-txt .txt-box .txt em:after {
        height: 14px; }
    .noscarna-qna .section02 .btm-txt .img-box {
      margin-top: 40px; }
  .noscarna-qna .section03 .sec03-circle-wrap {
    width: 670px;
    margin: 70px auto 0;
    padding-bottom: 269px; }
    .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle {
      width: 287px;
      height: 287px;
      padding: 35px; }
      .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle .txt {
        font-size: 32px; }
      .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle:nth-child(2) {
        top: 268px;
        margin-left: -143px; }
  .noscarna-qna .section04 {
    padding-bottom: 160px; }
    .noscarna-qna .section04 .title-wrap .title {
      margin-bottom: 67px; } }

@media screen and (max-width: 1023px) and (-ms-high-contrast: active), (max-width: 1023px) and (-ms-high-contrast: none) {
  .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt {
    max-height: inherit !important; }
  .noscarna-qna .section02 .qna-sec02-cnt {
    background-size: contain; } }

@media (max-width: 1023px) {
  /* 다국어 */
  /* 영문 */
  :lang(en) #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
    font-size: 3.06667vw; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: 3.46667vw; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    top: 31.33333vw;
    left: 9.06667vw; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
    top: 31.33333vw;
    right: 14.26667vw; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 33.33333vw; }
  :lang(en) .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
    font-size: 2.53333vw; }
  :lang(en) .noscarna-qna .section02 .btm-txt .txt-box .txt {
    font-size: 4.13333vw; }
  :lang(en) .product-info .section02 .precautions .btn-download {
    width: 52vw; }
  :lang(en) .product-info .section02 .product-detail-wrap .title a {
    padding: 2.93333vw 4vw; }
  :lang(en) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -1.06667vw; }
  :lang(en) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -1.06667vw;
    left: 0; }
  :lang(en) .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
    line-height: 1.55; } }

@media screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 750px) {
  :lang(en) #header-sub .header-content .nav .swiper-wrapper .swiper-slide a .txt {
    font-size: 23px; }
  :lang(en) .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
    padding: 29px 30px; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: 26px; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    top: 235px;
    left: 68px; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
    top: 235px;
    right: 107px; }
  :lang(en) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 250px; }
  :lang(en) .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
    font-size: 19px; }
  :lang(en) .noscarna-qna .section02 .btm-txt .txt-box .txt {
    font-size: 31px; }
  :lang(en) .product-info .section02 .precautions .btn-download {
    width: 390px; }
  :lang(en) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -8px; }
  :lang(en) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -8px;
    left: 0; } }

@media (max-width: 1023px) {
  /* 일문 */
  :lang(ja) .ui-accordion .accordion-item .btn .txt:lang(ko) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .btn .txt:lang(en) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .btn .txt:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  :lang(ja) .ui-accordion .accordion-item .btn .txt:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(ko) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(en) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(ja) .brand .title-wrap .sub-txt span {
    word-break: break-all; }
  :lang(ja) .brand .title-wrap .sub-txt {
    max-width: 810px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    top: 32.53333vw;
    left: 7.06667vw; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
    top: 32.53333vw;
    right: 12vw; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 31.06667vw;
    bottom: 7.06667vw; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: 3.06667vw; }
  :lang(ja) .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle .txt {
    font-size: 3.73333vw; }
  :lang(ja) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -1.86667vw; }
  :lang(ja) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -1.33333vw;
    left: 0; } }

@media screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 750px) {
  :lang(ja) .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit {
    font-size: 36px; }
  :lang(ja) .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
    padding: 31px 30px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
    font-size: 23px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    top: 244px;
    left: 53px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(2) {
    top: 244px;
    right: 90px; }
  :lang(ja) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 233px;
    bottom: 53px; }
  :lang(ja) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -14px; }
  :lang(ja) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -10px;
    left: 0; } }

@media (max-width: 1023px) {
  /* 중문 */
  :lang(zh) .ui-accordion .accordion-item .btn .txt:lang(ko) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .btn .txt:lang(en) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .btn .txt:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  :lang(zh) .ui-accordion .accordion-item .btn .txt:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(ko) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(en) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(zh) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    left: 16vw; }
  :lang(zh) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 37.33333vw;
    bottom: 4.93333vw; }
  :lang(zh) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -2vw; }
  :lang(zh) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -1.2vw;
    left: 0; } }

@media screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 750px) {
  :lang(zh) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(1) {
    left: 120px; }
  :lang(zh) .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt:nth-child(3) {
    left: 280px;
    bottom: 37px; }
  :lang(zh) .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -15px; }
  :lang(zh) .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -9px;
    left: 0; } }

@media (min-width: 1024px) {
  /*! contents - pc */
  .noscarna .sec03-cnt-wrap p .ref-txt {
    top: -15px;
    left: -2px; }
  .noscarna .sec03-cnt-wrap span .ref-txt {
    top: -10px;
    left: -1px; }
  .noscarna .fix-indi button:hover {
    color: #ff683f; }
    .noscarna .fix-indi button:hover:after {
      background-color: #ff683f; }
  .noscarna .fix-indi button.on {
    color: #ff683f; }
    .noscarna .fix-indi button.on:after {
      background-color: #ff683f; }
  .noscarna .fix-indi.change-other-color button:hover {
    color: #ff683f; }
    .noscarna .fix-indi.change-other-color button:hover:after {
      background-color: #ff683f; }
  .noscarna .fix-indi.change-other-color button.on {
    color: #ff683f; }
    .noscarna .fix-indi.change-other-color button.on:after {
      background-color: #ff683f; }
  .noscarna .sec .title-wrap .title {
    margin-bottom: 17px; }
    .noscarna .sec .title-wrap .title span {
      display: block;
      letter-spacing: -1px;
      font-size: 52px;
      line-height: 1.3; }
      .noscarna .sec .title-wrap .title span:lang(ko) {
        font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .noscarna .sec .title-wrap .title span:lang(en) {
        font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .noscarna .sec .title-wrap .title span:lang(ja) {
        font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 300; }
      .noscarna .sec .title-wrap .title span:lang(zh) {
        font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 300; }
      .noscarna .sec .title-wrap .title span em {
        letter-spacing: -1px;
        font-family: "NotoSans-Bold";
        font-size: 52px;
        line-height: 1.3;
        color: #ff683f; }
  .noscarna .sec .title-wrap .sub-tit {
    max-width: 670px;
    margin: auto;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 30px; }
    .noscarna .sec .title-wrap .sub-tit:lang(ko) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .noscarna .sec .title-wrap .sub-tit:lang(en) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .noscarna .sec .title-wrap .sub-tit:lang(ja) {
      font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
    .noscarna .sec .title-wrap .sub-tit:lang(zh) {
      font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
  .noscarna .sec .title-wrap .tit {
    margin: 20px 0 38px; }
  .noscarna .sec .title-wrap .sub-txt {
    max-width: 100%; }
    .noscarna .sec .title-wrap .sub-txt span {
      display: block;
      margin-bottom: 0;
      font-size: 22px;
      line-height: 1.5; }
      .noscarna .sec .title-wrap .sub-txt span em:lang(ko) {
        font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .noscarna .sec .title-wrap .sub-txt span em:lang(en) {
        font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .noscarna .sec .title-wrap .sub-txt span em:lang(ja) {
        font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 700; }
      .noscarna .sec .title-wrap .sub-txt span em:lang(zh) {
        font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 700; }
      .noscarna .sec .title-wrap .sub-txt span .ref-txt {
        position: relative;
        top: -5px;
        left: -4px;
        font-style: normal; }
      .noscarna .sec .title-wrap .sub-txt span:last-child {
        margin-top: 10px;
        font-size: 22px;
        line-height: 1.5; }
        .noscarna .sec .title-wrap .sub-txt span:last-child:lang(ko) {
          font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .noscarna .sec .title-wrap .sub-txt span:last-child:lang(en) {
          font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .noscarna .sec .title-wrap .sub-txt span:last-child:lang(ja) {
          font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 700; }
        .noscarna .sec .title-wrap .sub-txt span:last-child:lang(zh) {
          font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 700; }
  .noscarna .section01 {
    position: relative;
    background: url(../../images/brand/nc_sec01_bg.png) no-repeat center;
    background-size: cover; }
    .noscarna .section01 .lang-select {
      width: 90px;
      position: absolute;
      top: -75px;
      right: 0;
      margin-left: auto; }
      .noscarna .section01 .lang-select select {
        display: none; }
      .noscarna .section01 .lang-select .select-custom {
        position: relative; }
        .noscarna .section01 .lang-select .select-custom .btn-select-custom {
          position: relative;
          width: 100%;
          margin: 0;
          padding: 12px 20px;
          font-family: "Barlow";
          font-weight: 500;
          text-align: left;
          border-radius: 10px;
          border: 2px solid #172636;
          cursor: pointer; }
          .noscarna .section01 .lang-select .select-custom .btn-select-custom.on {
            border-radius: 10px 10px 0 0; }
            .noscarna .section01 .lang-select .select-custom .btn-select-custom.on::after {
              transform: rotate(180deg); }
          .noscarna .section01 .lang-select .select-custom .btn-select-custom::after {
            content: "";
            position: absolute;
            top: 18px;
            right: 14px;
            width: 12px;
            height: 7px;
            background: url(../../images/health/ico_arrow_bottom.png) no-repeat center;
            background-size: contain; }
        .noscarna .section01 .lang-select .select-custom .select-dropdown {
          position: absolute;
          top: 46px;
          left: 0;
          display: none;
          width: 100%;
          border-radius: 0 0 5px 5px;
          overflow-y: auto;
          z-index: 1;
          text-align: left;
          box-sizing: border-box;
          background: #222; }
        .noscarna .section01 .lang-select .select-custom .custom-opt {
          color: #fff;
          padding: 11px 0 13px;
          text-align: center;
          cursor: pointer;
          font-family: "Barlow";
          font-weight: 500; }
          .noscarna .section01 .lang-select .select-custom .custom-opt:hover {
            color: #ff683f; }
        .noscarna .section01 .lang-select .select-custom.on .btn-select-custom::after {
          transform: rotate(180deg); }
    .noscarna .section01 .sec01-cnt {
      margin-top: 4.16667vw; }
      .noscarna .section01 .sec01-cnt .img-box {
        width: 100%;
        text-align: center; }
        .noscarna .section01 .sec01-cnt .img-box .txt-img {
          position: absolute;
          top: -13px;
          left: 24%;
          margin-left: -82px;
          opacity: 0; }
  .noscarna .section02 .feature-cnt {
    margin: 2.44792vw auto 0;
    width: 1000px; }
    .noscarna .section02 .feature-cnt .sec02-circle-wrap {
      text-align: center; }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle {
        float: left;
        margin-top: 90px;
        width: 250px;
        height: 250px;
        border-radius: 100%;
        padding: 30px;
        align-self: center;
        border: 3px dotted #ff8f70;
        display: flex;
        justify-content: center;
        box-sizing: border-box; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
          align-self: center;
          font-size: 22px;
          line-height: 1.5;
          text-overflow: ellipsis;
          white-space: normal;
          word-wrap: break-word;
          overflow: hidden;
          -webkit-line-clamp: 5;
          display: -webkit-box;
          -webkit-box-orient: vertical; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt:lang(ko) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt:lang(en) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:last-child {
          width: 390px;
          margin-left: 300px;
          height: 128px;
          border-radius: 128px;
          margin-top: 30px;
          position: relative; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:last-child:before {
            content: "";
            display: block;
            clear: both;
            position: absolute;
            top: -34px;
            left: -125px;
            width: 56px;
            height: 56px;
            background: url(../../images/brand/nc_sec02_line.png) no-repeat; }
          .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle:last-child:after {
            content: "";
            display: block;
            clear: both;
            position: absolute;
            top: -34px;
            right: -125px;
            width: 56px;
            height: 56px;
            background: url(../../images/brand/nc_sec02_line.png) no-repeat;
            transform: rotate(-90deg); }
      .noscarna .section02 .feature-cnt .sec02-circle-wrap .img-circle {
        float: left;
        position: relative;
        margin: 0 48px; }
        .noscarna .section02 .feature-cnt .sec02-circle-wrap .img-circle .product-img {
          position: absolute;
          top: 98px;
          left: 0; }
  .noscarna .section03 {
    position: relative;
    padding: 100px 0 180px; }
    .noscarna .section03 .bg-sec {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/brand/nc_sec03_bg.png) no-repeat;
      background-size: cover; }
    .noscarna .section03 .title-wrap {
      margin-bottom: 3.38542vw; }
      .noscarna .section03 .title-wrap .title span {
        color: #fff; }
        .noscarna .section03 .title-wrap .title span em {
          color: #ff683f; }
    .noscarna .section03 .power-cnt {
      margin-top: 5.20833vw; }
      .noscarna .section03 .power-cnt .sec03-cnt-wrap {
        display: flex;
        justify-content: space-between; }
        .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 {
          position: relative;
          width: 340px;
          padding-left: 0.78125vw;
          padding-bottom: 2.44792vw;
          box-sizing: border-box;
          overflow: hidden; }
          .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
            margin-bottom: 30px;
            text-align: left;
            font-size: 24px;
            line-height: 1.3;
            color: #fff;
            min-height: 96px; }
            .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p span {
              display: block;
              font-size: 18px;
              line-height: 1.9; }
          .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box {
            overflow: hidden;
            text-align: left; }
            .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num {
              display: inline-block;
              font-size: 52px;
              color: #fff; }
              .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(ko) {
                font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(en) {
                font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(ja) {
                font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 900; }
              .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(zh) {
                font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 900; }
              .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num + .ui-move-num {
                margin-right: -4px; }
          .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03:after {
            content: "";
            display: block;
            clear: both;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 0;
            height: 8px;
            background-color: #ff683f;
            transition: 0.8s cubic-bezier(0.66, 0, 0.34, 1) all; }
          .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 + .sec03 {
            margin-left: 3.64583vw; }
          .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03.on:after {
            width: 100%; }
      .noscarna .section03 .power-cnt .sec03-references {
        padding: 1.04167vw 1.04167vw 0;
        text-align: left;
        color: #fff;
        line-height: 1.5; }
    .noscarna .section03 .inner-references {
      margin-top: 50px;
      text-align: left;
      color: #fff; }
      .noscarna .section03 .inner-references p {
        margin-bottom: 8px;
        text-align: left;
        font-size: 13px; }
      .noscarna .section03 .inner-references span {
        display: block;
        padding-left: 14px;
        text-indent: -14px;
        text-align: left;
        font-size: 13px;
        line-height: 1.2; }
        .noscarna .section03 .inner-references span a {
          font-size: 14px; }
  .noscarna .section05 .media-cnt .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ff683f; }
  .product-info .title-wrap .title span em {
    color: #ff805e; }
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .active-box {
    opacity: 1;
    background-color: #ff805e; }
  .product-info .section02 .product-detail-wrap .title a {
    background-color: #ff805e; }
  .noscarna-qna .title-wrap {
    padding: 0 0 0.67708vw; }
    .noscarna-qna .title-wrap .title span em {
      color: #ff683f; }
    .noscarna-qna .title-wrap .sub-txt {
      max-width: 100%; }
  .noscarna-qna .section01 {
    background-color: #fff5f2; }
    .noscarna-qna .section01 .qna-sec01-cnt {
      display: flex;
      margin: 1.77083vw 0 1.875vw;
      justify-content: space-between; }
      .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt {
        position: relative;
        width: 384px;
        align-self: end; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .img-box {
          width: 100%;
          height: 290px; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .img-box img {
            width: 100%;
            height: 100%; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box {
          padding: 40px;
          background-color: #fc754f;
          box-sizing: border-box; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .step {
            display: block;
            margin-bottom: 10px;
            font-size: 18px;
            color: #fff;
            font-family: "Barlow";
            font-weight: 500; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit {
            margin-bottom: 20px;
            font-size: 30px;
            line-height: 1.5;
            color: #fff; }
            .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(ko) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(en) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(ja) {
              font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
            .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(zh) {
              font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .txt {
            font-size: 20px;
            line-height: 1.5;
            color: #fff; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt + .sec01-cnt {
          margin-left: 24px;
          margin-bottom: auto; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(odd) {
          margin-top: 50px; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(even) .txt-box {
          background-color: #fff; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(even) .txt-box .step {
            color: #fc754f; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(even) .txt-box .tit {
            color: #222; }
          .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt:nth-child(even) .txt-box .txt {
            color: #222; }
        .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .shadow-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2); }
    .noscarna-qna .section01 .qna-sec01-info .txt {
      display: block;
      position: relative;
      padding-left: 25px;
      font-size: 15px;
      line-height: 1.6;
      color: #797e76; }
      .noscarna-qna .section01 .qna-sec01-info .txt:before {
        content: "";
        display: block;
        clear: both;
        position: absolute;
        top: 5px;
        left: 0;
        width: 16px;
        height: 16px;
        background: url(../../images/mo/ico_detail.png);
        background-size: cover;
        opacity: .6; }
  .noscarna-qna .section02 {
    padding-bottom: 7.29167vw; }
    .noscarna-qna .section02 .title-wrap .title span em {
      margin-right: -13px; }
    .noscarna-qna .section02 .qna-sec02-cnt {
      position: relative;
      width: 1100px;
      height: 320px;
      padding: 0 120px;
      margin: 1.5625vw auto 2.60417vw;
      box-sizing: border-box;
      background: url(../../images/brand/nc03_sec02_img01.png) no-repeat center; }
      .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap {
        position: absolute;
        top: 240px;
        display: flex;
        width: calc(100% - 240px);
        justify-content: space-between; }
        .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap .txt {
          max-width: 33.33333%;
          max-height: 73px;
          display: inline-block;
          padding: 10px 30px;
          text-overflow: ellipsis;
          white-space: normal;
          word-wrap: break-word;
          overflow: hidden;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-size: 18px;
          line-height: 1.6;
          color: #fff;
          background-color: #ef6540;
          border-radius: 23px;
          box-sizing: border-box; }
    .noscarna-qna .section02 .btm-txt {
      display: flex; }
      .noscarna-qna .section02 .btm-txt .txt-box img {
        display: block;
        max-width: 190px;
        margin: 50px 0 20px; }
      .noscarna-qna .section02 .btm-txt .txt-box .txt {
        display: block;
        letter-spacing: -1px;
        word-break: keep-all;
        font-size: 24px;
        line-height: 1.5; }
        .noscarna-qna .section02 .btm-txt .txt-box .txt em {
          position: relative;
          margin-right: -4px; }
          .noscarna-qna .section02 .btm-txt .txt-box .txt em:after {
            content: "";
            display: block;
            clear: both;
            position: absolute;
            bottom: 2px;
            width: 0%;
            height: 10px;
            background-color: #ffe4dc;
            z-index: -1;
            transition: 0.8s cubic-bezier(0.66, 0, 0.34, 1) all; }
      .noscarna-qna .section02 .btm-txt .txt-box.on .txt em:after {
        width: 100%; }
      .noscarna-qna .section02 .btm-txt .img-box {
        margin-left: auto; }
  .noscarna-qna .section03 {
    position: relative;
    padding: 100px 0 200px; }
    .noscarna-qna .section03 .bg-sec {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/brand/nc03_sec03_bg.png) no-repeat center;
      background-size: cover;
      z-index: -1; }
    .noscarna-qna .section03 .sec03-circle-wrap {
      display: flex;
      width: 800px;
      margin: 1.82292vw auto 0;
      justify-content: space-between; }
      .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle {
        overflow: hidden;
        display: flex;
        width: 240px;
        height: 240px;
        padding: 35px;
        border-radius: 100%;
        background-color: rgba(250, 104, 64, 0.7);
        justify-content: center;
        box-sizing: border-box;
        text-align: center; }
        .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle .txt {
          align-self: center;
          text-overflow: ellipsis;
          white-space: normal;
          word-wrap: break-word;
          overflow: hidden;
          -webkit-line-clamp: 5;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-size: 22px;
          line-height: 1.4;
          color: #fff; }
        .noscarna-qna .section03 .sec03-circle-wrap .sec03-circle:nth-child(2) {
          margin-top: 40px; }
  .noscarna-qna .section04 {
    background-color: #f1f5f8; }
    .noscarna-qna .section04 .title-wrap .title {
      margin-bottom: 3.64583vw; }
    .noscarna-qna .section04 .ui-accordion .accordion-item .btn .ico-q {
      color: #ff683f; } }

@media (min-width: 1024px) and (min-width: 1920px) {
  .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 {
    padding-left: 15px; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 + .sec03 {
      margin-left: 70px; } }

@media screen and (min-width: 1024px) and (min-width: 1024px) and (max-width: 1200px) {
  .noscarna .section01 .lang-select {
    right: 10px; }
  .noscarna .section01 .sec01-cnt .img-box .txt-img {
    left: 18%; }
  .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num {
    font-family: "NotoSans-Bold";
    font-size: 50px;
    color: #fff; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(ko) {
      font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(en) {
      font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(ja) {
      font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 900; }
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 .move-box .ui-move-num:lang(zh) {
      font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 900; }
  .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .img-box {
    height: 218px; }
  .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .step {
    font-size: 14px;
    color: #fff;
    font-family: "Barlow";
    font-weight: 500; }
  .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit {
    font-size: 26px;
    color: #fff; }
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(ko) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(en) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(ja) {
      font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
    .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .tit:lang(zh) {
      font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
  .noscarna-qna .section01 .qna-sec01-cnt .sec01-cnt .txt-box .txt {
    font-size: 14px;
    line-height: 1.5;
    color: #fff; }
  .noscarna-qna .section02 .qna-sec02-cnt {
    width: 100%;
    background-size: contain; }
    .noscarna-qna .section02 .qna-sec02-cnt .ingredient-wrap {
      top: 220px; } }

@media (min-width: 1024px) {
  /* 다국어 */
  /* 영문 */
  :lang(en) .noscarna .sec .title-wrap .sub-tit {
    line-height: 1.5; }
  :lang(en) .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
    font-size: 21px; }
  :lang(en) .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
    min-height: 158px; }
  :lang(en) .noscarna .sec03-cnt-wrap .ref-txt {
    top: -13px; } }

@media screen and (min-width: 1024px) and (min-width: 1024px) and (max-width: 1200px) {
  :lang(en) .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
    min-height: 179px; } }

@media screen and (min-width: 1024px) and (min-width: 751px) and (max-width: 1275px) {
  :lang(en) #header-sub .header-content .nav .swiper-wrapper {
    justify-content: left;
    margin-left: 100px; } }

@media (min-width: 1024px) {
  /* 일문 */
  :lang(ja) .ui-accordion .accordion-item .btn .txt:lang(ko) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .btn .txt:lang(en) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .btn .txt:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  :lang(ja) .ui-accordion .accordion-item .btn .txt:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(ko) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(en) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(ja) .ui-accordion .accordion-item .detail p:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(ja) #header-sub .header-content .util-menu .pharmacy {
    margin-right: 2.08333vw; }
  :lang(ja) .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
    font-size: 22px; }
  :lang(ja) .noscarna .sec .title-wrap .sub-txt span {
    letter-spacing: -1px; }
  /* 중문 */
  :lang(zh) .ui-accordion .accordion-item .btn .txt:lang(ko) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .btn .txt:lang(en) {
    font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .btn .txt:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  :lang(zh) .ui-accordion .accordion-item .btn .txt:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(ko) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(en) {
    font-family: "NotoSans-DemiLight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(ja) {
    font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(zh) .ui-accordion .accordion-item .detail p:lang(zh) {
    font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400; }
  :lang(zh) .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle {
    padding: 0; } }
