@charset "utf-8";





/*! contents - pc */

// 브랜드 스토리
.noscarna {

  //주석
  .sec03-cnt-wrap p .ref-txt {
    top: -15px;
    left: -2px;
  }
  .sec03-cnt-wrap span .ref-txt {
    top: -10px;
    left: -1px;
  }

  .fix-indi {
    button {
      &:hover {
        color: #ff683f;
        &:after {
          background-color: #ff683f;
        }
      }
      &.on {
        color: #ff683f;
        &:after {
          background-color: #ff683f;
        }
      }
    }
    &.change-other-color {
      button {
        &:hover {
          color: #ff683f;
          &:after {
            background-color: #ff683f;
          }
        }

        &.on {
          color: #ff683f;
          &:after {
            background-color: #ff683f;
          }
        }
      }
    }
  }
  .sec {
    .title-wrap {
      .title {
        margin-bottom: 17px;
        span {
          display: block;
          letter-spacing: -1px;
          @include notoSansL();
          font-size: $fontSizePc + 36;
          line-height: 1.3;
          em {
            letter-spacing: -1px;
            @include font($NotoSansB, $fontSizePc + 36, 1.3, #ff683f);
          }
        }
      }
      .sub-tit {
        max-width: 670px;
        margin: auto;
        @include text-overflow-multi(2);
        @include notoSansM();
        font-size: $fontSizePc + 14;
      }
      .tit {
        margin: 20px 0 38px;
      }
      .sub-txt {
        max-width: 100%;
        span {
          display: block;
          margin-bottom: 0;
          font-size: $fontSizePc + 6;
          line-height: 1.5;

          em {
            @include notoSansM();
          }
          .ref-txt {
            position: relative;
            top: -5px;
            left: -4px;
            font-style: normal;
          }
          &:last-child {
            margin-top: 10px;
            // font-family: $NotoSansM;
            @include notoSansM();
            font-size: $fontSizePc + 6;
            line-height: 1.5;
          }
        }
      }
    }
  }
  .section01 {
    position: relative;
    background: url(../../images/brand/nc_sec01_bg.png) no-repeat center;
    background-size: cover;
    .lang-select{
      width: 90px;
      position: absolute;
      top: -75px;right: 0;
      margin-left: auto;
      select{
        display: none;
      }
      .select-custom{
        position: relative;
        .btn-select-custom{
          position: relative;
          width: 100%;
          margin: 0;
          padding: 12px 20px;
          @include fontE($fontEng, 500);
          // border: 2px solid #222;
          text-align: left;
          border-radius: 10px;
          // background: #222;
          // color:#fff;
          border: 2px solid #172636;
          cursor: pointer;
          &.on {
            border-radius: 10px 10px 0 0;

            &::after {
              transform: rotate(180deg);
            }
          }

          &::after{
            content: "";
            position: absolute;
            top: 18px;
            right: 14px;
            width: 12px;
            height: 7px;
            // background: url(../../images/health/ico_arrow_bottom_white.png) no-repeat center;
            background: url(../../images/health/ico_arrow_bottom.png) no-repeat center;
            background-size: contain;
          }
        }
        .select-dropdown{
            position: absolute;
            top: 46px;
            left: 0;
            display: none;
            width: 100%;
            border-radius: 0 0 5px 5px;
            overflow-y: auto;
            z-index: 1;
            text-align: left;
            box-sizing: border-box;
            background: #222;;
        }
        .custom-opt{
            color:#fff;
            padding: 11px 0 13px;
            text-align: center;
            cursor: pointer;
            @include fontE($fontEng, 500);

            &:hover {
              color:#ff683f;
            }
        }
        &.on{
          .btn-select-custom{
            &::after{
                transform: rotate(180deg);
            }
            
          }
        }
      }
    }
    .sec01-cnt {
      margin-top: get-vw(80px);
      // padding-bottom: get-vw(57px);
      .img-box {
        width: 100%;
        text-align: center;
        .txt-img {
          position: absolute;
          top: -13px;
          left: 24%;
          margin-left: -82px;
          opacity: 0;
        }
      }
    }
  }
  .section02 {
    .feature-cnt {
      margin: get-vw(47px) auto 0;
      width: 1000px;
      .sec02-circle-wrap {
        text-align: center;
        .sec02-circle {
          float:left;
          margin-top: 90px;
          width: 250px;height: 250px;
          border-radius: 100%;
          padding: 30px;
          align-self: center;
          border: 3px dotted #ff8f70;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          .txt {
            align-self: center;
            @include notoSansM();
            font-size: $fontSizePc + 6;
            line-height: 1.5;
            @include text-overflow-multi(5)
          }

          &:last-child {
            width:390px;
            margin-left: 300px;
            height: 128px;
            border-radius: 128px;
            margin-top: 30px;
            position: relative;

            &:before {
              content:"";
              display: block;
              clear: both;
              position: absolute;
              top: -34px;left: -125px;
              width: 56px;height: 56px;
              background: url(../../images/brand/nc_sec02_line.png) no-repeat;
            }
            &:after {
              content:"";
              display: block;
              clear: both;
              position: absolute;
              top: -34px;right: -125px;
              width: 56px;height: 56px;
              background: url(../../images/brand/nc_sec02_line.png) no-repeat;
              transform: rotate(-90deg);
            }
          }
        }
        .img-circle {
          float:left;
          position: relative;
          margin: 0 48px;
          .product-img {
            position: absolute;
            top: 98px;left: 0;
          }
        }
      }
    }
  }
  .section03 {
    position: relative;
    padding: 100px 0 180px;
    .bg-sec {
      position: absolute;
      top: 0;left: 0;
      width: 100%;height: 100%;
      background: url(../../images/brand/nc_sec03_bg.png) no-repeat;
      background-size: cover;
    }
    .title-wrap {
      margin-bottom: get-vw(65px);
      .title {
        span {
          color: #fff;
          em {
            color: #ff683f;
          }
        }
      }
    }
    .power-cnt {
      margin-top: get-vw(100px);
      // padding-bottom: get-vw(50px);
      .sec03-cnt-wrap {
        display: flex;
        // padding: 0 get-vw(20px);
        justify-content: space-between;
        .sec03 {
          position: relative;
          width: 340px;
          padding-left: get-vw(15px);
          padding-bottom: get-vw(47px);
          box-sizing: border-box;
          overflow: hidden;
          p {
            margin-bottom: 30px;
            text-align: left;
            font-size: $fontSizePc + 8;
            line-height: 1.3;
            color: #fff;
            min-height: 96px;
            span {
              display: block;
              font-size: $fontSizePc + 2;
              line-height: 1.9;
            }
            // .ref-txt {
            //   position: relative;
            //   top: -13px;left: -3px;
            //   font-style: normal;
            //   font-size: $fontSizePc;
            // }
          }
          .move-box {
            overflow: hidden;
            text-align: left;
            .ui-move-num {
              display: inline-block;
              @include notoSansB();
              font-size: $fontSizePc + 36;
              color: #fff;
              & + .ui-move-num {
                margin-right: -4px;
              }
            }
          }
          &:after {
            content:"";
            display: block;
            clear: both;
            position: absolute;
            bottom: 0;left: 0;right: 0;
            width: 0;height: 8px;
            background-color: #ff683f;
            transition: 0.8s cubic-bezier(0.66, 0, 0.34, 1) all;

          }
          & + .sec03 {
            margin-left: get-vw(70px);
          }
          &.on {
            &:after {
              width: 100%;
            }
          }
        }
      }
      .sec03-references {
        padding: get-vw(20px) get-vw(20px) 0;
        text-align: left;
        color: #fff;
        line-height: 1.5;
      }
    }
    .inner-references {
      margin-top: 50px;
      text-align: left;
      color:#fff;

      p {
        margin-bottom: 8px;
        text-align: left;
        font-size: 13px;
      }
      span {
        display: block;
        padding-left: 14px;
        text-indent: -14px;
        text-align: left;
        font-size: 13px;
        line-height: 1.2;
        a {
          font-size: 14px;
        }
      }
    }
  }
  .section05 {
    .media-cnt {
      .swiper-pagination {
        .swiper-pagination-bullet-active {
          background-color: #ff683f;
        }
      }
    }
  }
}

//제품정보
.product-info {
  .title-wrap {
    .title {
      span {
        em {
          color: #ff805e;
        }
      }
    }
  }
  .section01 {
    .product-swiper {
      .swiper-wrapper {
        .swiper-slide.on {
          .active-box {
            opacity: 1;
            background-color: #ff805e;
          }
        }
      }
    }
  }
  .section02 {
    .product-detail-wrap {
      .title {
        a {
          background-color: #ff805e;
        }
      }
    }
  }
}


// 궁금해요
.noscarna-qna {
  .title-wrap {
    padding: 0 0 get-vw(13px);
    .title {
      span {
        em {
          color: #ff683f;
        }
      }
    }
    .sub-txt {
      max-width: 100%;
    }
  }
  .section01 {
    background-color: #fff5f2;
    .qna-sec01-cnt {
      display: flex;
      margin: get-vw(34px) 0 get-vw(36px);
      justify-content: space-between;
      .sec01-cnt {
        position: relative;
        width: 384px;
        align-self: end;
        .img-box {
          width: 100%;
          height: 290px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt-box {
          padding: 40px;
          background-color: #fc754f;
          box-sizing: border-box;
          .step {
            display: block;
            margin-bottom: 10px;
            font-size: $fontSizePc + 2;
            color: #fff;
            @include fontE($fontEng, 500);
          }
          .tit {
            margin-bottom: 20px;
            // font-family: $NotoSansM;
            @include notoSansM();
            font-size: $fontSizePc + 14;
            line-height: 1.5;
            color: #fff;
          }
          .txt {
            font-size: $fontSizePc + 4;
            line-height: 1.5;
            color: #fff;
          }
        }
        & + .sec01-cnt{
          margin-left:24px;
          margin-bottom: auto;
        }
        &:nth-child(odd) {
          margin-top: 50px;
        }
        &:nth-child(even) {
          .txt-box {
            background-color: #fff;
            .step {
              color: #fc754f;
            }
            .tit {
              color: #222;
            }
            .txt {
              color: #222;
            }
          }

        }
        .shadow-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
    .qna-sec01-info {
      // padding-bottom: get-vw(194px);
      .txt {
        display: block;
        position: relative;
        padding-left: 25px;
        font-size: 15px;
        line-height: 1.6;
        color: #797e76;
        &:before {
          content:"";
          display: block;
          clear: both;
          position: absolute;
          top: 5px;left: 0;
          width: 16px;height: 16px;
          background: url(../../images/mo/ico_detail.png);
          background-size: cover;
          opacity: .6;
        }
      }
    }
  }
  .section02 {
    padding-bottom: get-vw(140px);
    .title-wrap {
      .title {
        span {
          em {
            margin-right: -13px;
          }
        }
      }
    }
    .qna-sec02-cnt {
      position: relative;
      width: 1100px;
      height: 320px;
      padding: 0 120px;
      margin: get-vw(30px) auto get-vw(50px);
      box-sizing: border-box;
      background: url(../../images/brand/nc03_sec02_img01.png) no-repeat center;
      .ingredient-wrap {
        position: absolute;
        top: 240px;
        display: flex;
        width: calc(100% - 240px);
        justify-content: space-between;
        .txt {
          max-width: 33.33333%;
          max-height: 73px;
          display: inline-block;
          padding: 10px 30px;
          @include text-overflow-multi(2);
          font-size: $fontSizePc + 2;
          line-height: 1.6;
          color: #fff;
          background-color: #ef6540;
          border-radius: 23px;
          box-sizing: border-box;
        }
      }
    }
    .btm-txt {
      display: flex;
      .txt-box {
        img {
          display: block;
          max-width: 190px;
          margin: 50px 0 20px;
        }
        .txt {
          display: block;
          letter-spacing: -1px;
          word-break: keep-all;
          font-size: $fontSizePc + 8;
          line-height: 1.5;
          em {
            position: relative;
            margin-right: -4px;
            &:after {
              content:"";
              display: block;
              clear: both;
              position: absolute;
              bottom: 2px;
              width: 0%;height: 10px;
              background-color: #ffe4dc;
              z-index: -1;
              transition: 0.8s cubic-bezier(0.66, 0, 0.34, 1) all;
            }
          }
        }

        &.on {
          .txt {
            em {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
      .img-box {
        margin-left: auto;
      }
    }
  }
  .section03 {
    position: relative;
    padding: 100px 0 200px;
    .bg-sec {
      position: absolute;
      top: 0;left: 0;
      width: 100%;height: 100%;
      background: url(../../images/brand/nc03_sec03_bg.png) no-repeat center;
      background-size: cover;
      z-index: -1;
    }
    .sec03-circle-wrap {
      display: flex;
      width: 800px;
      margin: get-vw(35px) auto 0;
      // padding-bottom: get-vw(400px);
      justify-content: space-between;
      .sec03-circle {
        overflow: hidden;
        display: flex;
        width: 240px;
        height: 240px;
        padding: 35px;
        border-radius: 100%;
        background-color: rgba(250,104,64,0.7);
        justify-content: center;
        box-sizing: border-box;
        text-align: center;
        .txt {
          align-self: center;
          @include text-overflow-multi(5);
          font-size: $fontSizePc + 6;
          line-height: 1.4;
          color: #fff;
        }
        &:nth-child(2) {
          margin-top: 40px;
        }
      }
    }
  }
  .section04 {
    background-color: #f1f5f8;
    .title-wrap {
      .title {
        margin-bottom: get-vw(70px);
      }
    }
    .ui-accordion {
      .accordion-item {
        .btn {
          .ico-q {
            color: #ff683f;
          }
        }
      }
    }
  }
}

// wide
@media (min-width: 1920px) {

  //브랜드 스토리
  .noscarna {
    .section03 {
      .power-cnt {
        .sec03-cnt-wrap {
          .sec03 {
            padding-left: 15px;
            & + .sec03 {
              margin-left: 70px;
            }
          }
        }
      }
    }
  }

}

@media screen and (min-width: 1024px) and (max-width: 1340px) {

}

// 태블릿
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  //브랜드스토리
  .noscarna {
    .section01 {
      .lang-select {
        right: 10px;
      }
      .sec01-cnt {
        .img-box {
          .txt-img {
            left: 18%;
          }
        }
      }
    }
    .section03 {
      .power-cnt {
        .sec03-cnt-wrap {
          .sec03 {
            .move-box {
              .ui-move-num {
                font-family: $NotoSansB;
                @include notoSansB();
                font-size: $fontSizePc + 34;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }


  // 궁금해요
  .noscarna-qna {
    .section01 {
      .qna-sec01-cnt {
        .sec01-cnt {
          .img-box {
            height: 218px;
          }
          .txt-box {
            .step {
              font-size: 14px;
              color: #fff;
              @include fontE($fontEng, 500);
            }
            .tit {
              // font-family: $NotoSansM;
              @include notoSansM();
              font-size: $fontSizePc + 10;
              color: #fff;
            }
            .txt {
              font-size: 14px;
              line-height: 1.5;
              color: #fff;
            }
          }
        }
      }
    }
    .section02 {
      .qna-sec02-cnt {
        width: 100%;
        background-size: contain;
        .ingredient-wrap {
          top: 220px;
        }
      }
    }
  }
}

/* 다국어 */

/* 영문 */
:lang(en) {

  //pc
  //브랜드 스토리
  .noscarna .sec .title-wrap .sub-tit {
    line-height: 1.5;
  }

  .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle .txt {
    font-size: 21px;
  }

  .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
    min-height: 158px;
  }

  .noscarna .sec03-cnt-wrap .ref-txt {
    top: -13px;
  }

  // 태블릿
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
      min-height: 179px;
    }
  }

  @media screen and (min-width: 751px) and (max-width: 1275px) {
    #header-sub .header-content .nav .swiper-wrapper {
      justify-content: left;
      margin-left: 100px;
    }
  }

}

/* 일문 */
:lang(ja) {
  .ui-accordion .accordion-item .btn .txt {
    @include notoSansM();
  }

  .ui-accordion .accordion-item .detail p {
    @include notoSansDL();
  }

  #header-sub .header-content .util-menu .pharmacy {
    margin-right: get-vw(40px);
  }

  .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
    font-size: 22px;
  }

  .noscarna .sec .title-wrap .sub-txt span {
    letter-spacing: -1px;
  }

  .noscarna .sec03-cnt-wrap .ref-txt {
    // top: -13px;
  }

  @media screen and (min-width: 751px) and (max-width: 1275px) {
    .noscarna .section03 .power-cnt .sec03-cnt-wrap .sec03 p {
      // min-height: 132px;
    }
  }

}

/* 중문 */
:lang(zh) {
  .ui-accordion .accordion-item .btn .txt {
    @include notoSansM();
  }

  .ui-accordion .accordion-item .detail p {
    @include notoSansDL();
  }

  .noscarna .section02 .feature-cnt .sec02-circle-wrap .sec02-circle {
    padding: 0;
  }

  .noscarna .sec03-cnt-wrap .ref-txt {
    // top: -10px;
  }


}






